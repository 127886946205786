"use client";

// Error boundaries must be Client Components
import { Button } from "@/components/ui/button";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  const router = useRouter();
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);
  return <div className="flex flex-col items-center justify-center h-[calc(100vh-64px)] gap-4 max-w-screen-sm mx-auto" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <div className="text-center">
        <h2 className="text-2xl font-bold">Something went wrong!</h2>
        <p className="text-sm text-muted-foreground">{error.message}</p>
      </div>
      <Button variant="outline" onClick={
    // Attempt to recover by trying to re-render the segment
    () => {
      router.refresh();
      return reset();
    }} data-sentry-element="Button" data-sentry-source-file="error.tsx">
        Try again
      </Button>
    </div>;
}